<template>
  <div id="loginPage" class="flex min-h-screen justify-center">
    <div class="w-full">
      <div class="items-center justify-center flex h-full">
        <div class="px-10 md:px-0 py-8" style="width: 27rem">
          <AppLogo class="w-20 mx-auto pb-12 md:pb-16" :is-loading="false" />

          <slot />
        </div>
      </div>
    </div>
    <div class="hidden lg:block w-full login-right"></div>
  </div>
</template>
<script setup lang="ts">
import AppLogo from "~/components/AppLogo.vue";

useHead({
  titleTemplate: (title) => title ? `${title} | Fynn` : 'Fynn Billing Platform',
});
</script>

<style lang="scss">
#loginPage {
  @apply bg-gradient-to-tr from-slate-50 to-[#ECFBFF];

  input,
  .formkit-form .p-button {
    @apply py-2.5;
  }

  .login-right {
    background-color: var(--surface-hover);
  }
}
</style>
